"use-strict";

// Fade Animate
const animateFade = (fadeOut, fadeIn, extra = undefined) => {
  fadeOut.classList.remove("bd-in");
  fadeOut.classList.add("bd-out");
  setTimeout(() => {
    fadeOut.classList.add("hidden");
    fadeIn.classList.remove("bd-out");
    fadeIn.classList.remove("hidden");
    fadeIn.classList.add("bd-in");
    if (extra) extra.classList.add("hidden");
  }, 200);
};

// MODALS

const close_msg = document.getElementById("message-close");
const modal_msg = document.getElementById("message-modal");
const bd_msg = document.getElementById("message-bd");
const msg_arr = Array.from(document.querySelectorAll(".msg-btn"));
const container_msg = document.getElementById("modal-container");
close_msg.addEventListener("click", () => {
  bd_msg.classList.remove("bd-in");
  modal_msg.classList.remove("modal-enter");
  bd_msg.classList.add("bd-out");
  modal_msg.classList.add("modal-out");
  setTimeout(() => {
    container_msg.classList.add("hidden");
  }, 200);
});
msg_arr.forEach((btn_msg) => {
  btn_msg.addEventListener("click", () => {
    container_msg.classList.remove("hidden");
    bd_msg.classList.add("bd-in");
    modal_msg.classList.add("modal-enter");
    bd_msg.classList.remove("bd-out");
    modal_msg.classList.remove("modal-out");
  });
});
//////////////////////////////////////////////////////////
// Form
const form = document.querySelector("form");
const fname = document.getElementById("message-fname");
const lname = document.getElementById("message-lname");
const msg_email = document.getElementById("message-email");
const success_div = document.getElementById("success-msg");
const error_div = document.getElementById("error-msg");
const form_div = document.getElementById("form-container");
const load_div = document.getElementById("load-msg");
const msg = document.getElementById("message-msg");
const sub_form = document.getElementById("sub-form");

const getResponse = async (data) => {
  try {
    animateFade(form_div, load_div, sub_form);
    const res = await fetch(
      "https://quiet-earth-38834.herokuapp.com/sendEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const json = await res.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

form.addEventListener("submit", async (e) => {
  e.preventDefault();
  let name = `${fname.value} ${lname.value}`;
  let email = msg_email.value;
  let message = msg.value;

  const data = {
    name,
    email,
    message,
  };
  form.reset();

  let { status, message: mesg } = await getResponse(data);
  if (status === "success") {
    animateFade(load_div, success_div);
  } else if (status === "error") {
    Array.from(error_div.children)[1].textContent = `An Error Occured: ${mesg}`;
    animateFade(load_div, error_div);
  }
});
///////////////////////////////////////////////////////
// Scroll Up
const btn_up = document.getElementById("scroll-top");
const features = document.getElementById("features");
const nav = document.getElementById("nav");

btn_up.addEventListener("click", () => {
  nav.scrollIntoView({ behavior: "smooth" });
});

const obsCallback = ([entry]) => {
  if (!entry.isIntersecting) {
    btn_up.classList.remove("bd-out");
    btn_up.classList.remove("hidden");
    btn_up.classList.add("bd-in");
  } else {
    btn_up.classList.remove("bd-in");
    btn_up.classList.add("bd-out");
    setTimeout(() => btn_up.classList.add("hidden"), 200);
  }
};
const obsOptions = {
  root: null,
  threshold: 0,
};

const featureObserver = new IntersectionObserver(obsCallback, obsOptions);
featureObserver.observe(features);

/////////////////////////////////////////////////////////
// Nav
const btn_pop = document.getElementById("btn-pop");
const popup_nav = document.getElementById("popup-nav");
const close_pop = document.getElementById("close_pop_nav");
const mob_nav = document.getElementById("mob-nav");
const open_mob_nav = document.getElementById("btn-mob-nav");
const btn_service = document.getElementById("service-btn");
const service = document.getElementById("service");

btn_service.addEventListener("click", () => {
  service.classList.toggle("hidden");
  service.classList.toggle("modal-enter");
});

btn_pop.addEventListener("click", () => {
  popup_nav.classList.remove("modal-out");
  popup_nav.classList.remove("hidden");
  popup_nav.classList.add("modal-enter");
});
btn_pop.addEventListener("blur", () => {
  popup_nav.classList.remove("modal-enter");
  popup_nav.classList.add("modal-out");
  setTimeout(() => {
    popup_nav.classList.add("hidden");
  }, 200);
});

close_pop.addEventListener("click", () => {
  mob_nav.classList.remove("modal-enter");
  mob_nav.classList.add("modal-out");
  setTimeout(() => {
    mob_nav.classList.add("hidden");
  }, 200);
});
open_mob_nav.addEventListener("click", () => {
  mob_nav.classList.remove("modal-out");
  mob_nav.classList.remove("hidden");
  mob_nav.classList.add("modal-enter");
});
